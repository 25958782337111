// Core
import { z } from "zod";
import { TFunction } from "i18next";

// Utils
import { getFieldLabel } from "@/client/utils/forms";

export enum LoginFormEnum {
  login = "login",
  password = "password",
}

const i18NextPath = "inputs:";
const i18ReportPath = `${i18NextPath}errorMessages`;
const errorReport = {
  required: `${i18ReportPath}.required`,
  min: `${i18ReportPath}.min`,
  invalidPwdWhitespaces: `${i18ReportPath}.invalidPwdWhitespaces`,
};

const RX = {
  password: /^\S$|^\S[ \S]*\S$/,
};

export const inputConfig = {
  [LoginFormEnum.login]: {
    label: "",
    placeholder: getFieldLabel("login", true),
    required: errorReport.required,
  },
  [LoginFormEnum.password]: {
    label: "",
    placeholder: `${i18NextPath}password.placeholder`,
    required: errorReport.required,
    min: 8,
    minText: errorReport.min,
    patternWhitespaces: RX.password,
    invalidWhiteSpaces: errorReport.invalidPwdWhitespaces,
  },
};

export const loginForm = {
  shape: {
    [LoginFormEnum.login]: "",
    [LoginFormEnum.password]: "",
  },
  schema: (t: TFunction<["buttons", "inputs", "signin"]>) => {
    return z.object({
      [LoginFormEnum.login]: z
        .string()
        .trim()
        .refine((v) => v.trim().length, {
          message: t(inputConfig.login.required),
        }),
      [LoginFormEnum.password]: z
        .string({
          required_error: t(inputConfig.password.required),
        })
        .trim()
        .min(
          inputConfig.password.min,
          t(inputConfig.password.minText, {
            label: t(inputConfig.password.placeholder).toLowerCase(),
            count: inputConfig.password.min,
            defaultValue: "",
          }),
        )
        .regex(inputConfig.password.patternWhitespaces, t(inputConfig.password.invalidWhiteSpaces)),
    });
  },
};
