// Core
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import useSWRMutation from "swr/mutation";
import { useRouter } from "next/navigation";
import HttpStatusCode from "http-status-codes";

// Definitions
import type { FormSubmitParams } from "@/client/components/form/form.types";
import type { HttpResponseAxiosDataType, HttpResponseAxiosErrorType } from "@/common/types/http";

// Hooks
import { useNotification } from "@/client/hooks/use-notification";

// Feature
import type { SignupRequestType, InternalSignupRequestResponseType } from "../types";
import { signupRequestKeys } from "../query";
import { signupRequestApi } from "../infrastructure";

// Utils
import {
  getHttpErrorMessage,
  getHttpSuccessMessageCallBack,
} from "@/client/utils/notifications/notifications-msg";
import { fillServerErrorsToForm } from "@/client/utils/forms";
import { routes } from "@/client/definitions/book";

type OnSignupRequestSubmitCbType = (values: FormSubmitParams<SignupRequestType>) => void;
type UseSignupRequestCreateType = {
  loadingSignupRequest: boolean;
  onSignupRequestSubmit: OnSignupRequestSubmitCbType;
};

const fetcher = (_key: string, { arg }: { arg: SignupRequestType }) =>
  signupRequestApi.signupRequestCreate({ data: arg });

export const useSignupRequestCreate = (): UseSignupRequestCreateType => {
  const router = useRouter();
  const { t, i18n } = useTranslation();
  const { showSuccess, showError } = useNotification();
  const signupRequestMutation = useSWRMutation<
    HttpResponseAxiosDataType<InternalSignupRequestResponseType>,
    HttpResponseAxiosErrorType,
    string,
    SignupRequestType
  >(signupRequestKeys.signupRequest, fetcher);

  const onSignupRequestSubmit = useCallback((data: FormSubmitParams<SignupRequestType>) => {
    signupRequestMutation
      .trigger({ ...data.values })
      .then((res) => {
        data?.acts?.reset?.();
        showSuccess(getHttpSuccessMessageCallBack(t));
        return res.data?.uuid && router.push(`${routes.verification}/${res.data.uuid}`);
      })
      .catch((e: HttpResponseAxiosErrorType) => {
        const error = e.response?.data;
        const status = e.response.status;
        const isError400 = status === HttpStatusCode.BAD_REQUEST;
        const isError422 = status === HttpStatusCode.UNPROCESSABLE_ENTITY;
        const isError500 = status && status >= HttpStatusCode.INTERNAL_SERVER_ERROR;
        if (isError400 || isError500) {
          return showError(getHttpErrorMessage(t));
        }
        if (isError422 && error.errors) {
          return fillServerErrorsToForm({
            i18n,
            errors: error.errors,
            setError: data?.acts?.setError,
          });
        }
      });
  }, []);

  return {
    loadingSignupRequest: signupRequestMutation.isMutating,
    onSignupRequestSubmit,
  };
};
