// Definitions
import {
  type FormatPhoneOptions,
  PhoneNumberFormatType,
  PhoneNumberFormatter,
} from "./phone-number-formatter";
import { UserCountryCodesEnum, UserCountryCodesKeys } from "@/common/types/phone";

const formatter = new PhoneNumberFormatter();
const phoneNumberFormats = formatter.getPhoneNumberFormats();

export const getFormatPhoneNumber = (
  phoneNumber: string,
  country: UserCountryCodesEnum = UserCountryCodesEnum.en,
): string => {
  const countriesFormat: Record<UserCountryCodesKeys, PhoneNumberFormatType> = {
    en: phoneNumberFormats.SPECIAL,
    fr: phoneNumberFormats.SPECIAL,
    de: phoneNumberFormats.INTERNATIONAL,
    at: phoneNumberFormats.INTERNATIONAL,
  };

  const optionsFormatter: FormatPhoneOptions = {
    regionCode: country,
    formatPhone: countriesFormat[country],
  };

  return formatter.formatPhoneNumber(phoneNumber, optionsFormatter);
};
