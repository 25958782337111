// Components
import { Control, Controller, FieldValues, Path } from "react-hook-form";
import { InputPassword } from "@/client/components/ui/FormFields/InputPassword";

// Definitions
import type { IInputPasswordProps } from "@/client/components/ui/FormFields/InputPassword";

interface WrappedFormPasswordType<ControlT extends FieldValues> extends IInputPasswordProps {
  name: Path<ControlT>;
  control?: Control<ControlT>;
}

export const WrappedFormPassword = <T extends FieldValues>(props: WrappedFormPasswordType<T>) => {
  const { name, control, ...rest } = props;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => <InputPassword {...rest} {...field} ref={field.ref} />}
    />
  );
};
