// Core
import { ReactElement, forwardRef } from "react";
import cx from "classnames";

// Components
import { Input } from "antd";

// Definitions
import { PasswordProps } from "antd/lib/input";
import { type InputRefBaseType } from "../InputBase";

// Utils
import st from "./styles.module.css";

export interface IInputPasswordProps extends PasswordProps {
  name: string;
  maxLength?: number;
  hasDisabled?: boolean;
}

export const InputPassword = forwardRef<InputRefBaseType, IInputPasswordProps>(
  (props, ref): ReactElement => {
    const { className, hasDisabled = false, size = "large", ...rest } = props;

    const inputStyle = cx(
      st.input,
      {
        [st.disabled]: hasDisabled,
      },
      className,
    );

    return (
      <Input.Password
        {...rest}
        size={size}
        className={inputStyle}
        disabled={hasDisabled}
        role="password-textbox"
        ref={ref}
      />
    );
  },
);
InputPassword.displayName = "InputPassword";
