// Core
import useSWR from "swr";

// Definitions
import type { StaticDataType, StaticDataItemType } from "../types";
import type { HttpResponseAxiosDataType, HttpResponseAxiosErrorType } from "@/common/types/http";

// Utils
import { staticDataApi } from "../infrastructure";
import { staticDataBaseKeys } from "../query";

export type UsePhoneCodesFetchType = () => {
  loadingPhoneCodes: boolean;
  phoneCodes: StaticDataItemType[];
};

const fetcher = () => staticDataApi.fetchPhoneCodes();

export const usePhoneCodesFetch: UsePhoneCodesFetchType = () => {
  const { data, isLoading } = useSWR<
    HttpResponseAxiosDataType<StaticDataType>,
    HttpResponseAxiosErrorType
  >(staticDataBaseKeys.phoneCodes, fetcher);

  return {
    loadingPhoneCodes: isLoading,
    phoneCodes: data?.data?.items || [],
  };
};
