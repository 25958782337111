// Core
import { ParseKeys } from "i18next/typescript/t";

export const testIds = {
  sectionTestId: "landing-pro-wholesalers",
  containerTestId: "landing-pro-wholesalers-container",
};

export const benefits: { text: ParseKeys<"signup"> }[] = [
  { text: "pro-wholesalers.benefits.item1" },
  { text: "pro-wholesalers.benefits.item2" },
  { text: "pro-wholesalers.benefits.item3" },
  { text: "pro-wholesalers.benefits.item4" },
];
export const i18nKeys = {
  heading: "signup:pro-wholesalers.headline" as const,
  benefits,
};
