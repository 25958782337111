"use client";

// Core
import { useTranslation } from "react-i18next";

// Components
import { Link } from "@/client/components/others/link";
import { Text } from "@/client/components/ui/Typography/Text";

// Module
import st from "./styles.module.css";

// Utils
import { composeGmailLink } from "@/client/utils/email";
import { emails, phoneNumbers } from "@/client/definitions/contacts";
import { getFormatPhoneNumber } from "@/client/utils/phone-number/phone-format";

export const SigninFormInfo = ({
  testId = "signin-form-service-contacts",
}: {
  testId?: string;
}) => {
  const { t } = useTranslation("signin");

  const needHelpText = t("form.needHelp");
  const emailText = t("form.emailText");
  const testIdContactPhoneNumber = testId ? `${testId}-phone-number` : "contact-phone-number";
  const testIdHelpService = testId ? `${testId}-help-service` : "help-service";

  const formattedPhoneNumber = getFormatPhoneNumber(String(phoneNumbers.helpService));

  return (
    <div className={st["signin-form-info"]} data-testid={testId}>
      <Text box="full" size="14" color="gray-900" font="system" alignmentHorizontal="center">
        <>
          <p className={st["signin-form-info-text"]}>
            {needHelpText}
            &#8194;
            <Link href={`tel:${formattedPhoneNumber}`} testId={testIdContactPhoneNumber}>
              {formattedPhoneNumber}
            </Link>
          </p>
          <p className={st["signin-form-info-text"]}>
            {emailText}
            &#8194;
            <Link
              testId={testIdHelpService}
              href={composeGmailLink(emails.helpService)}
              blank
              asNextLink={false}
            >
              {emails.helpService}
            </Link>
          </p>
        </>
      </Text>
    </div>
  );
};
