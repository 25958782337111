// Core
import { useRouter } from "next/navigation";

// Domain
import { useLoginCreate } from "@/client/domains/auth/signin/hooks/use-login";

// Components
import { SigninForm } from "@/client/domains/auth/signin/components/signin-form";
import { SigninFormInfo } from "@/client/domains/auth/signin/components/signin-form-info/signin-form-info";

// Utils
import st from "./styles.module.css";
import { routes } from "@/client/definitions/book";

export const SigninRequest = () => {
  const { loadingLoginCreate, onLogin } = useLoginCreate();
  const router = useRouter();

  const handleRedirectToSignup = () => router.push(routes.signup);

  return (
    <div className={st["request-form__holder"]}>
      <SigninForm
        onSubmit={onLogin}
        loading={loadingLoginCreate}
        onRedirectToSignup={handleRedirectToSignup}
        testId="signin-page-signin-form"
      />
      <SigninFormInfo />
    </div>
  );
};
