// Definitions
import type { StaticDataType } from "@/client/domains/static-data/types";
import type { HttpResponseApiDataType } from "@/common/types/http";

// Utils
import { http } from "@/client/transport/http";

export const staticDataApi = Object.freeze({
  fetchPhoneCodes: () => {
    return http.get<HttpResponseApiDataType<StaticDataType>>("/static-data/country/phone-codes");
  },
  fetchLanguages: () => {
    return http.get<HttpResponseApiDataType<StaticDataType>>("/static-data/country/languages");
  },
});
