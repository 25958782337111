"use client";

// Domains
import { usePhoneCodesFetch } from "@/client/domains/static-data/hooks/use-phone-codes-fetch";

// Hooks
import { useUserCountry } from "@/client/hooks/use-ussr-country";

// Components
import { SignupRequestForm } from "@/client/domains/signup/signup-request/components/signup-request-form";
import { SignupRequestFormInfo } from "@/client/domains/signup/signup-request/components/signup-request-form-info";

// Module
import st from "./styles.module.css";
import { useSignupRequestCreate } from "../../hooks/use-signup-request-create";

type SignupRequestPropsType = {
  formName: string;
};

export const SignupRequest = ({ formName }: SignupRequestPropsType) => {
  const { loadingSignupRequest, onSignupRequestSubmit } = useSignupRequestCreate();
  const { loadingPhoneCodes, phoneCodes } = usePhoneCodesFetch();
  const { country } = useUserCountry();

  return (
    <div className={st["signup-request-form__holder"]}>
      <SignupRequestForm
        phoneCodes={phoneCodes}
        country={country}
        formName={formName}
        onSubmit={onSignupRequestSubmit}
        loadingCreate={loadingSignupRequest}
        loadingStaticData={loadingPhoneCodes}
        testId="signup-page-signup-request-form"
      />
      <SignupRequestFormInfo />
    </div>
  );
};
