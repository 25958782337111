// Core
import { z } from "zod";

// Definitions
import { TFunction } from "i18next";

// Utils
import { getFieldLabel } from "@/client/utils/forms";

export const SignupRequestFields = {
  name: "name",
  phoneNumber: "phoneNumber",
  phonePrefix: "phonePrefix",
  email: "email",
  id: "id",
} as const;

const regExps = {
  [SignupRequestFields.phoneNumber]: /^\d{3}[\d|\\+]\d{1,10}$/,
  [SignupRequestFields.email]: /^[^\s@]+@[^\s@]+\.[^\s@#!?$%^&*[\](`)}{+=]{2,}$/,
};

const generalWithTranslations: Record<string, string> = {
  defaultInputPlaceholder: "inputs:defaultPlaceholders.input",
  defaultSelectPlaceholder: "inputs:defaultPlaceholders.select",
  required: "inputs:errorMessages.required",
  invalidLetters: "inputs:errorMessages.invalidLetters",
  errorMin: "inputs:errorMessages.min",
  errorMax: "inputs:errorMessages.max",
  invalidPhone: "inputs:errorMessages.invalidPhone",
  emailPlaceholder: "inputs:email.placeholder",
  emailInvalid: "inputs:errorMessages.invalidEmail",
};

export const inputConfig = {
  [SignupRequestFields.name]: {
    label: getFieldLabel("fullName"),
    placeholder: getFieldLabel("fullName", true),
    required: generalWithTranslations.required,
    min: 1,
    minText: generalWithTranslations.errorMin,
    max: 35,
    maxText: generalWithTranslations.errorMax,
  },
  [SignupRequestFields.phonePrefix]: {
    label: "",
    placeholder: generalWithTranslations.defaultSelectPlaceholder,
    required: generalWithTranslations.required,
  },
  [SignupRequestFields.phoneNumber]: {
    label: getFieldLabel("phoneNumber"),
    placeholder: getFieldLabel("phoneNumber", true),
    required: generalWithTranslations.required,
    min: 5,
    minText: generalWithTranslations.errorMin,
    max: 13,
    maxText: generalWithTranslations.errorMax,
    pattern: regExps[SignupRequestFields.phoneNumber],
    invalid: generalWithTranslations.invalidPhone,
  },
  [SignupRequestFields.email]: {
    label: getFieldLabel("email"),
    placeholder: generalWithTranslations.emailPlaceholder,
    required: generalWithTranslations.required,
    pattern: regExps[SignupRequestFields.email],
    invalid: generalWithTranslations.emailInvalid,
    min: 6,
    max: 75,
  },
};

export const signupRequestForm = {
  shape: {
    [SignupRequestFields.name]: "",
    [SignupRequestFields.phoneNumber]: "",
    [SignupRequestFields.phonePrefix]: null,
    [SignupRequestFields.email]: "",
  },
  schema: (t: TFunction<["buttons", "inputs", "signup"]>) =>
    z.object({
      [SignupRequestFields.name]: z
        .string()
        .trim()
        .min(
          inputConfig[SignupRequestFields.name].min,
          t(inputConfig[SignupRequestFields.name].minText, {
            label: t(inputConfig[SignupRequestFields.name].placeholder).toLowerCase(),
            count: inputConfig[SignupRequestFields.name].min,
          }),
        )
        .max(
          inputConfig[SignupRequestFields.name].max,
          t(inputConfig[SignupRequestFields.name].maxText, {
            label: t(inputConfig[SignupRequestFields.name].placeholder).toLowerCase(),
            count: inputConfig[SignupRequestFields.name].max,
          }),
        )
        .refine((v) => v.trim().length > 0, {
          message: t(inputConfig[SignupRequestFields.name].required),
        }),
      [SignupRequestFields.phonePrefix]: z
        .object(
          {
            id: z.number(),
            label: z.string(),
            value: z.string(),
            icon: z.string(),
          },
          {
            required_error: t(inputConfig[SignupRequestFields.phonePrefix].required),
            invalid_type_error: t(inputConfig[SignupRequestFields.phonePrefix].required),
          },
        )
        .required({
          id: true,
          label: true,
          value: true,
        }),
      [SignupRequestFields.phoneNumber]: z
        .string({
          required_error: t(inputConfig[SignupRequestFields.phoneNumber].required),
          invalid_type_error: t(inputConfig[SignupRequestFields.phoneNumber].required),
        })
        .trim()
        .min(
          inputConfig[SignupRequestFields.phoneNumber].min,
          t(inputConfig[SignupRequestFields.phoneNumber].minText, {
            label: t(inputConfig[SignupRequestFields.phoneNumber].label).toLowerCase(),
            count: inputConfig[SignupRequestFields.phoneNumber].min,
          }),
        )
        .regex(
          inputConfig[SignupRequestFields.phoneNumber].pattern,
          t(inputConfig[SignupRequestFields.phoneNumber].invalid),
        ),
      [SignupRequestFields.email]: z
        .string()
        .trim()
        .regex(
          inputConfig[SignupRequestFields.email].pattern,
          t(inputConfig[SignupRequestFields.email].invalid),
        ),
    }),
};
