// Definitions
import type { HttpResponseApiDataType } from "@/common/types/http";
import { InternalSignupRequestResponseType, SignupRequestParamsType } from "./types";

// Utils
import { http } from "@/client/transport/http";

export const signupRequestApi = Object.freeze({
  signupRequestCreate: (payload: SignupRequestParamsType) => {
    const { data } = payload;
    return http.post<HttpResponseApiDataType<InternalSignupRequestResponseType>>(
      "/registration",
      data,
    );
  },
});
