// Core
import { useTranslation } from "react-i18next";
import { Text } from "@/client/components/ui/Typography/Text";

// Module
import st from "./styles.module.css";

export const SignupRequestFormInfo = () => {
  const { t } = useTranslation("signup");

  return (
    <div className={st["signup-terms"]} data-testid="signup-request-form-info">
      <Text size="12" color="gray-700">
        {t("form.terms")}
      </Text>
    </div>
  );
};
