// Core
import { PropsWithChildren } from "react";
import cx from "classnames";

// Components
import { Grid } from "@/client/components/ui/Grid";
import { Title } from "@/client/components/ui/Typography/Title";

// Modules
import st from "./styles.module.css";

type FormTitleProps = PropsWithChildren & {
  testId?: string;
  gutter?: "small" | "middle" | "large";
};

export const TitleBlock = ({
  children,
  testId = "title-block",
  gutter = "middle",
}: FormTitleProps) => {
  const { md = true, lg = true } = Grid.useBreakpoint();

  const isMobile = !md && !lg;
  const titleStyles = cx(st.title, st[`title--${gutter}`]);

  return (
    <Title
      tag="h3"
      size={isMobile ? "18" : "24"}
      color="gray-900"
      alignmentHorizontal="center"
      className={titleStyles}
      data-testid={testId}
    >
      {children}
    </Title>
  );
};
